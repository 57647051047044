import React from "react"
import { Link, graphql } from "gatsby"
import { PrismicRichText } from '@prismicio/react'
import Copyright from '../components/copyright'
import Footer from '../components/footer'
import Contact from '../components/contact'
import Seo from '../components/seo'
import { linkResolver } from '../utils/link_resolver'
import Banner from '../components/banner'
import Header from '../components/header'
import Nav from '../components/nav'

export const query = graphql`
  query {
    prismicPeople {
      data {
        body {
          richText
        }
        page_title
        meta_description
        image {
          gatsbyImageData
          alt
        }
        heading {
          text
        }
      }
    }
  }
`

const PeoplePage = ({data}) => {
  const entry = data.prismicPeople
  if (!entry) return null
  return (
  <>
    <Seo title={entry.data.page_title} description={entry.data.meta_description} image="" pathname={linkResolver(entry.data)} />  
    <Nav />
    <Header />
    <Banner image={entry.data.image} />
    <div className="w-full font-sans py-12 sm:py-24">
      <div className="w-9/12 mx-auto max-w-screen-2xl">
        <div className="flex-none md:flex gap-24 justify-between">
          <div className="w-full md:w-8/12 prose text-dark-green md:prose-lg leading-relaxed">
            <h1>{entry.data.heading.text}</h1>
            <PrismicRichText field={entry.data.body.richText} />  
          </div>
          <div className="w-full md:w-4/12 mt-24 md:mt-0">
            <div className="bg-grey-e prose p-6 leading-relaxed">
              <h3>Lawyers &amp; Patent Attorneys</h3>
              <p>McCabes are experts in all aspects of Australian and New Zealand IP rights, protection and law. We act for clients in NZ, Australia and internationally.</p>
              <p>For advice on securing, protecting and defending your IP assets, please contact us today.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Contact image={entry.data.image} />
    <Footer />
    <Copyright />
  </>
  )
}

export default PeoplePage